import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";
import qs from "query-string";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import axios from "axios";

const setLogin = gql`
  mutation setLogin($form: LoginForm) {
    result: setLogin(Input: $form) {
      success
      errorType
      sessionID
    }
  }
`;

const getIntegrationDBChooseList = gql`
  query getIntegrationDBChooseList {
    getRecommendClass {
      dbID
      ebName
      enEbName
      hyintID
      url
      maintain
      gIsQuery
      fullTextNo
      eIsQuery
    }
  }
`;

@withI18next(["common"])
@page
class DLink extends React.Component {
  constructor(props) {
    super(props);
    let webAppName =
      props.appStore.globalConfig["jumperrwd.common.webAppName"] || "";
    let hyssoConfig =
      props.appStore.globalConfig["jumperrwd.hysso.clientConfig"] || "";
    this.state = {
      auth: props.readerStore.auth,
      webAppName: webAppName,
      hyssoConfig: hyssoConfig,
    };
  }

  async componentDidMount() {
    await this.dLink(this.props);
  }

  @autobind
  redirectURL(url) {
    if (url) {
      let base_href = this.props.appStore.globalConfig["jumperrwd.common.base_href"];
      let navigateTo = false;
      if (!/^http[s]?:\/\//.test(url) && url !== "") {
        if (!url.startsWith("/")) {
          url = "/" + url;
        }
        navigateTo = true;
      }
      if (url.includes(base_href)) {
        url = url.replace(base_href, "");
        if (!url.startsWith("/")) {
          url = "/" + url;
        }
        navigate(url);
      } else if (navigateTo) {
        navigate(url);
      } else {
        window.location.href = url;
      }
    }
  }

  @autobind
  async dLink(props) {
    let { appStore, search } = props;
    let { globalConfig, resourceConfig } = appStore;
    let params = { ...search };
    let {
      rType,
      uid,
      pass,
      url,
      mode,
      searchField,
      searchInput,
      op,
      resourceType,
      searchTarget,
      db,
      centralized,
      decentralized,
      id,
      token,
      lang,
      ip,
      userid,
      ticket,
      t,
      searchBlockType,
    } = params;
    let ticketTemp = ticket || "";

    if (resourceType === "ejournal") {
      resourceType = "journal";
    }

    if (lang === "cht") {
      this.handleChangeLanguage("zh");
    } else if (lang === "eng") {
      this.handleChangeLanguage("en");
    }

    if ((uid && pass) || token || userid || ticket) {
      let uidTepmp = uid || "";
      let passTepmp = pass || "";
      let tokenTemp = token || "";
      let useridTepmp = userid || "";
      let tTemp = t || "";
      await client.jumperrwdClient
        .mutate({
          mutation: setLogin,
          variables: {
            form: {
              user: uidTepmp,
              pass: passTepmp,
              encryptType: "des",
              token: tokenTemp,
              userid: useridTepmp,
              ticket: ticketTemp,
              t: tTemp,
            },
          },
        })
        .then(async (res) => {
          if (res.data.result.success) {
            await this.timeout(1000);
            await this.setLogin();
          } else {
            if (res.data.result.errorType === 20) {
              let ticketFailedMessage = this.props.t(
                "jumperrwd.login.ticketLoginFailed"
              );
              if (
                ticketFailedMessage !== "jumperrwd.login.ticketLoginFailed" &&
                ticketFailedMessage !== ""
              ) {
                alert(ticketFailedMessage);
              }
            }
          }
        });
    }

    if (ip && ip !== "") {
      await axios
        .get("/api/jumperrwdWs/setSessionWithIP?ip=" + ip)
        .then((res) => {});
    }

    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";

    if (rType === "redirectByLogin") {
      if (this.state.auth) {
        this.redirectURL(url);
      } else {
        if (this.state.webAppName === "fcu") {
          this.redirectURL(url);
        } else if (this.state.hyssoConfig !== "") {
          let oauthParams = {
            login: "true",
            url: url,
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          this.redirectURL(url);
          /*let apiV3Params = {
            url: url,
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));*/
        }
      }
    } else if (rType === "resourceListByLogin") {
      let listParams = {
        pageType: "list",
        resourceType,
      };
      let listURL = ["/resourceList", qs.stringify(listParams)].join("?");
      if (this.state.auth) {
        navigate(listURL);
      } else {
        if (this.state.webAppName === "fcu") {
          navigate(listURL);
        } else if (this.state.hyssoConfig !== "") {
          let oauthParams = {
            login: "true",
            url: listURL,
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          navigate(listURL);
          /*let apiV3Params = {
            url: listURL,
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));*/
        }
      }
    } else if (rType === "resourceDetailByLogin") {
      let detailParams = {
        resourceType: resourceType ? resourceType : defaultResourceType,
        id,
      };
      let detailURL = ["/resourceDetail", qs.stringify(detailParams)].join("?");
      if (this.state.auth) {
        navigate(detailURL);
      } else {
        if (this.state.webAppName === "fcu") {
          navigate(detailURL);
        } else if (this.state.hyssoConfig !== "") {
          let oauthParams = {
            login: "true",
            url: detailURL,
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          navigate(detailURL);
          /*let apiV3Params = {
            url: detailURL,
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));*/
        }
      }
    } else if (rType === "resourceRedirectByLogin") {
      let redirectParams = {
        mode,
        searchField,
        searchInput,
        op,
        resourceType,
        searchTarget,
        searchBlockType,
      };
      searchField = [].concat(searchField || []);
      searchInput = [].concat(searchInput || []);
      op = [].concat(op || []);
      let rediertURL = ["/resourceRedirect", qs.stringify(redirectParams)].join(
        "?"
      );
      if (this.state.auth) {
        navigate(rediertURL);
      } else {
        if (this.state.webAppName === "fcu") {
          navigate(rediertURL);
        } else if (this.state.hyssoConfig !== "") {
          let oauthParams = {
            login: "true",
            url: rediertURL,
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          navigate(rediertURL);
          /*let apiV3Params = {
            url: rediertURL,
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));*/
        }
      }
    } else if (rType === "searchRedirectEdsByLogin") {
      let redirectParams = {
        searchInput,
        searchField,
        op,
        mode,
        centralized: "Y",
        decentralized: "N",
      };
      let rediertURL = ["/searchResult", qs.stringify(redirectParams)].join(
        "?"
      );
      if (this.state.auth) {
        navigate(rediertURL);
      } else {
        if (this.state.webAppName === "fcu") {
          navigate(rediertURL);
        } else if (this.state.hyssoConfig !== "") {
          let oauthParams = {
            login: "true",
            url: rediertURL,
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          navigate(rediertURL);
          /*let apiV3Params = {
            url: rediertURL,
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));*/
        }
      }
    } else if (rType === "resourceSearch") {
      let redirectParams = {
        mode,
        searchField,
        searchInput,
        op,
        resourceType,
        searchTarget,
        searchBlockType,
      };
      searchField = [].concat(searchField || []);
      searchInput = [].concat(searchInput || []);
      op = [].concat(op || []);
      let rediertURL = ["/resourceRedirect", qs.stringify(redirectParams)].join(
        "?"
      );
      if (this.state.auth) {
        navigate(rediertURL);
      } else {
        if (this.state.webAppName === "fcu") {
          navigate(rediertURL);
        } else if (this.state.hyssoConfig !== "") {
          let oauthParams = {
            login: "true",
            url: rediertURL,
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          navigate(rediertURL);
          /*let apiV3Params = {
            url: rediertURL,
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));*/
        }
      }
    } else if (rType === "integrationSearch") {
      db = [].concat(db || []);
      if (decentralized === "Y") {
        if (db.length === 0) {
          db = await client.jumperrwdClient
            .query({
              query: getIntegrationDBChooseList,
            })
            .then((res) => {
              let dbTemp = [];
              let { getRecommendClass } = res.data;
              getRecommendClass.forEach((item) => {
                dbTemp.push(item.dbID);
              });
              return dbTemp;
            });
        }
      }
      let redirectParams = {
        searchInput,
        searchField,
        op,
        db,
        mode,
        centralized,
        decentralized,
        searchBlockType,
      };
      let rediertURL = "";
      if (globalConfig["jumper.common.eds.issearchboxapi"] === "1") {
        let redirectEDSParams = {
          searchKey: searchInput,
        };
        rediertURL = [
          "/searchRedirectEDS",
          qs.stringify(redirectEDSParams),
        ].join("?");
      } else {
        rediertURL = ["/searchResult", qs.stringify(redirectParams)].join("?");
      }
      if (this.state.auth) {
        navigate(rediertURL);
      } else {
        if (this.state.webAppName === "fcu") {
          navigate(rediertURL);
        } else if (this.state.hyssoConfig !== "") {
          let oauthParams = {
            login: "true",
            url: rediertURL,
          };
          navigate(["/oauth", qs.stringify(oauthParams)].join("?"));
        } else {
          navigate(rediertURL);
          /*let apiV3Params = {
            url: rediertURL,
          };
          navigate(["/sendURLApiV3", qs.stringify(apiV3Params)].join("?"));*/
        }
      }
    } else {
      navigate("/");
    }
  }

  @autobind
  async setLogin() {
    await this.props.readerStore.syncSessionCookie();
  }

  @autobind
  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  @autobind
  handleChangeLanguage(lng) {
    axios
      .get("/api/jumperrwdWs/setSession?key=locales&value=" + lng)
      .then((res) => {});
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default DLink;
